.coin {
  display: flex;
  flex-direction: column;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto;
  gap: 4rem 4rem;
  grid-template-areas: "header" "chart" "favorite" "aside";
}

.coin__header {
  grid-area: header;
  display: flex;
  flex-direction: column;
}

.coin__info {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.coin__logo img {
  margin-right: 1.2rem;
  width: 4rem;
}

.coin__title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.coin__title h1 {
  font-size: 3.6rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  word-break: break-all;
}

.coin__symbol {
  color: var(--surface16);
  display: block;
  font-size: 2.4rem;
  font-weight: 300;
  margin: 0 0 0 2rem;
}

.coin__price {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 3.6rem;
  font-weight: 500;
}

.coin__change {
  font-size: 1.8rem;
  font-weight: 400;
  margin-left: 1.6rem;
}

.coin__favorite {
  grid-area: favorite;
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
}

.add-favorite {
  align-items: center;
  background: none;
  border: 1px solid var(--on-surface);
  color: var(--on-surface);
  cursor: pointer;
  display: flex;
  font-size: 1.82rem;
  justify-content: center;
  outline: none;
  padding: 1.6rem;
  width: 100%;
}

.add-favorite:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}

.add-favorite:active {
  background-color: darken(var(--primary), 4%);
  border-color: var(--primary);
  -webkit-box-shadow: inset 0px 0px .5rem var(--surface6);
     -moz-box-shadow: inset 0px 0px .5rem var(--surface6);
          box-shadow: inset 0px 0px .5rem var(--surface6);
}

.add-favorite svg {
  margin: 0 1.2rem .3rem 0;
}

.coin__chart {
  grid-area: chart;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
}

.coin__figure {
  margin-bottom: 40px;
}

.coin__details {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 2rem;
}

.details__datapoint {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.6rem;
}

.datapoint__header {
  color: var(--surface16);
  font-size: 1.8rem;
  font-weight: 400;
}

.datapoint__data {
  font-size: 1.8rem;
  font-weight: 300;
}

.coin__aside { 
  grid-area: aside;
}

.coin__aside h3 {
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 1;
  padding-bottom: 2.4rem;
}

@media (min-width: 45rem) {
  .grid-container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: auto auto;
    gap: 4rem 4rem;
    grid-template-areas: "header favorite" "chart aside";
  }

  .coin__header {
    align-items: center;
    flex-direction: row;
    margin-bottom: 4rem;
  }

  .coin__logo img {
    margin-right: 3rem;
    width: 6.4rem;
  }

  .coin__price {
    margin-left: 3rem;
  }

  .coin__chart {
    margin-bottom: 4rem;
  }

  .coin__details {
    align-items: center;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 0;
  }

  .details__datapoint {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 8.8rem;
  }

  .datapoint__header {
    font-size: 1.4rem;
  }

  .datapoint__data {
    margin-top: .6rem;
  }
}
