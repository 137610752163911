.hidden {
  visibility: hidden;
}

@media(max-width: calc(45rem - .01rem)) {
  .show-for-desktop {
      display: none;
    }
}

@media(min-width: 45rem) {
  .show-for-mobile {
    display: none;
  }
}
