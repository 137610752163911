@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
:root {
  --surface: #121212;
  --surface1: #1e1e1e;
  --surface2: #252525;
  --surface3: #2c2c2c;
  --surface4: #333333;
  --surface6: #3d3d3d;
  --surface8: #4d4d4d;
  --surface12: #5e5e5e;
  --surface16: #717171;
  --surface24: #888888;
  --surface48: #c4c4c4;
  
  --on-surface: #fff;
  --on-primary: #000;
  
  --primary: #bb86fc;
  --primary-light: #c597fc;
  --primary-variant: #3700b3;
  --secondary: #03dac6;
  
  --error: #cf6679;
  --positive: var(--secondary);  
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  background-color: var(--surface);
  color: var(--on-surface);
  font-family: 'Roboto', sans-serif;
  font-size: 1.6rem;
  line-height: 1.6;
}

h1 {
  font-size: 2.8rem;
  font-weight: 300;
  margin: 0 0 1.6rem 0;
  padding: 0;
}

h2 {
  font-size: 2.4rem;
  font-weight: 300;
  margin: 0 0 1.6rem 0;
  padding: 0;
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear;
}

@-webkit-keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.green-text {
  color: var(--positive);
}

.red-text {
  color: var(--error);
}

.light-text {
  font-weight: 300;
}

.hidden {
  visibility: hidden;
}

@media(max-width: calc(45rem - .01rem)) {
  .show-for-desktop {
      display: none;
    }
}

@media(min-width: 45rem) {
  .show-for-mobile {
    display: none;
  }
}

.content-container {
  margin: 0 auto;
  padding: 0 1.6rem;
  max-width: 120rem;
}

@media (min-width: 45rem) {
  .content-container {
    padding: 0 4rem;
  }
}

.header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 1.6rem 0;
  width: 100%;
}

.header a {
  color: var(--on-surface);
  text-decoration: none;
}

.mobile-menu svg {
  margin-top: 1rem;
}

.nav {
  background: var(--primary);
  border-top: .2rem solid var(--primary);
  left: 0;
  margin-top: 2rem;
  max-height: 1000px;
  overflow: hidden;
  position: absolute;
  top: 7.4rem;
  transition:  max-height 1s;
  width: 100%;
  z-index: 1000;
}

.nav-hidden {
  max-height: 0px;
  transition: max-height .3s;
}

.header__title {
  font-family: 'Ubuntu';
  font-size: 3.6rem;
  margin: 0;
  padding: 0;
}

.nav__link {
  border-bottom: .2rem solid var(--primary);
  color: var(--on-surface);
  display: block;
  font-size: 2rem;
  padding: 1rem 2rem;
  text-decoration: none;
  transition: color .2s;
  width: 100%;
}

.nav__link:hover {
  color: var(--primary-light);
}

.nav__link--active {
  border-bottom: .2rem solid var(--primary);
}

@media (min-width: 45rem) {
  .nav {
    background: none;
    border: none;
    height: 3.4rem;
    left: auto;
    margin-top: 0;
    position: static;
    top: auto;
    width: auto;
    z-index: 1;
  }

  .nav-hidden {
    max-height: 1000px;
  }

  .nav__link {
    border: none;
    display: inline;
    font-size: 1.8rem;
    margin-left: 3rem;
    padding: 0 0 .6rem 0;
    width: auto;
  }
}

@media (min-width: 90rem) {
  .nav__link {
    margin-left: 4rem;
  }
}

.index {
  margin-bottom: 60px;
}

.index-header {
  align-items: flex-end;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  margin-bottom: .6rem;
}

.index-header h2 {
  margin-bottom: 0;
}

.table-header {
  background-color: var(--surface1);
  border: .1rem solid var(--surface);
  color: var(--surface2)4;
  font-size: 1.4rem;
  font-weight: 400;
  padding: 1.8rem;
}

.table_header__sort {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
}

.table_header__sort svg {
  margin-bottom: .2rem;
}

.table_header__sort--right {
  justify-content: flex-end;
}

@media (min-width: 45rem) {
  .index-header {
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
  }

  .table-header {
    display: grid;
    grid-gap: 1.2rem;
    grid-template-columns: 50px 1fr 100px 125px 100px;
  }
}

h4.dash-link {
  margin-bottom: 1.2rem;
}

h4.dash-link a {
  color: var(--on-surface);
  font-weight: 400;
  text-decoration: none;
}

h4.dash-link a:hover {
  color: var(--primary-light);
}

.dashboard {
  margin-bottom: 6rem;
}

.updater {
  display: flex;
  font-size: 1.4rem;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.updater__button {
  align-items: center;
  background-color: var(--primary);
  border: none;
  display: flex;
  justify-content: center;
  margin-left: 1rem;
  padding: .4rem .8rem;
  outline: none;
}

.updater__button:hover {
  background-color: darken(var(--primary), 4%);
}

.updater__button:active {
  background-color: darken(var(--primary), 4%);
  box-shadow: inset 0px 0px .5rem var(--surface6);
   outline: none;
}

.updater__button svg {
  margin-right: .6rem;
}

.updater__time {
  display: inline-block;
  font-family: 'Roboto Mono';
  text-align: center;
  width: 6rem;
}
.coin-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: .1rem;
  margin-bottom: 40px;
}

.coin-tile {
  background-color: var(--surface1);
  border: .1rem solid var(--surface);
  border-top: none;
  color: var(--on-surface);
  cursor: pointer;
  display: grid;
  font-size: 2rem;
  font-weight: 400;
  grid-gap: 1.2rem;
  grid-template-columns: 50px 2fr 1fr;
  padding: 1.8rem;
  text-decoration: none;
  transition: background-color .2s;
}

.coin-tile:hover {
  background-color: var(--surface3);
}

.coin-tile > * {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.coin-tile__order {
  display: none;
  justify-content: center;
}

.coin-tile__logo {
  margin-right: 2rem;
}
  
.coin-tile__logo img {
  width: 3.6rem;
}

.coin-tile__name {
  align-items: flex-start;
  overflow: hidden;
  flex-direction: column;
}
  
.coin-tile__symbol {
  color: var(--surface2)4;
  display: inline-block;
  font-weight: 300;
}

.coin-tile__price {
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
}

.coin-tile__change {
  display: none;
  justify-content: flex-end;
}

.coin-tile__cap {
  display: none;
  justify-content: flex-end;
}

/* Favorite Tiles */

.fave-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: .1rem;
  margin-bottom: 40px;
}

.fave-list--more {
  grid-template-columns: 1fr;
}

.fave-tile {
  align-items: center;
  background-color: var(--surface1);
  border: .1rem solid var(--surface);
  border-top: none;
  color: var(--on-surface);
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  font-size: 2rem;
  justify-content: space-between;
  outline: none;
  padding: 1.2rem;
  text-decoration: none;
  transition: background-color .2s;
}

.fave-tile:hover {
  background-color: var(--surface3);
  color: var(--on-surface);
}

.fave-tile > * {
  pointer-events: none;
}

.fave-tile .fave-tile__overview {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
  text-align: left;
}

.fave-tile .fave-tile__overview .fave-tile__logo {
  align-items: center;
}

.fave-tile .fave-tile__overview .fave-tile__logo img {
  width: 3.2rem;
}

.fave-tile .fave-tile__overview .fave-tile__name {
  display: none;
  margin-right: 1.6rem;
  max-width: 13rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 45rem) {
    display: flex;
  }
}

.fave-tile .fave-tile__overview .fave-tile__symbol {
  color: var(--surface2)4;
  font-weight: 300;
}
  
.fave-tile .fave-tile__price {
  font-size: 1.8rem;
  margin-left: auto;
}

.fave-tile .fave-tile__price svg {
  margin: 0 0 .1rem 0;
  padding-top: .3rem;
}

.fave-tile--currentFave {
  border: .1rem solid var(--primary);
}

.fave-tile--remove:hover {
  background-color: var(--error);
  color: var(--on-primary);
}

.fave-tile--remove:hover .fave-tile__overview .fave-tile__symbol {
  color: var(--surface12);
}

.fave-tile--remove:hover .fave-tile__price {
  visibility: visible;
}

.fave-tile--remove .fave-tile__overview {
  flex-direction: row;
}

.fave-tile--remove .fave-tile__overview .fave-tile__logo {
  margin-right: 1rem;
}

.fave-tile--remove .fave-tile__overview .fave-tile__name {
  max-width: 18rem;
}

.fave-tile--remove .fave-tile__price {
  color: var(--error);
}

.fave-tile--add:hover {
  background-color: var(--positive);
  color: var(--on-primary);
}

.fave-tile--add:hover .fave-tile__overview .fave-tile__symbol {
  color: var(--surface12);
}

.fave-tile--add .fave-tile__overview {
  flex-direction: row;
}

.fave-tile--add .fave-tile__overview .fave-tile__name {
  max-width: none;
  overflow: visible;
}

.fave-tile--add .fave-tile__overview .fave-tile__symbol {
  font-weight: 300;
  margin-left: 2.4rem;
}

.fave-tile--add .fave-tile__price {
  visibility: visible;
}

.fave-tile--disabled {
  pointer-events: none;
  opacity: 0.4;
}
  
.fave-tile--disabled:hover {
  background-color: var(--surface1);
  color: var(--on-primary);
}

.fave-tile--disabled .fave-tile__price {
  visibility: hidden;
}

@media (min-width: 45rem) {
  .coin-tile {
    grid-template-columns: 50px 25px 1fr 100px 125px 100px;
  }

  .coin-tile__order {
    display: flex;
  }

  .coin-tile__logo img {
    width: 2.2rem;
  }

  .coin-tile__name {
    align-items: center;
    flex-direction: row;
  }

  .coin-tile__symbol {
    margin-left: 2.4rem;
  }

  .coin-tile__price {
    flex-direction: row;
  }

  .coin-tile__change {
    display: flex;
  }

  .coin-tile__cap {
    display: flex;
  }

  .fave-list {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .fave-tile .fave-tile__overview {
    flex-direction: row;
  }

  .fave-tile .fave-tile__overview .fave-tile__logo {
    display: flex;
  }

  .fave-tile .fave-tile__overview .fave-tile__logo img {
    margin-right: 2rem;
  }

  .fave-tile--remove .fave-tile__price {
    color: var(--on-surface);
    visibility: hidden;
  }

  .fave-tile--add .fave-tile__overview .fave-tile__logo img,
  .fave-tile--add .fave-tile__overview .fave-tile__logo svg {
      margin-left: 2.4rem;
  }
}

.coin {
  display: flex;
  flex-direction: column;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto;
  grid-gap: 4rem 4rem;
  gap: 4rem 4rem;
  grid-template-areas: "header" "chart" "favorite" "aside";
}

.coin__header {
  grid-area: header;
  display: flex;
  flex-direction: column;
}

.coin__info {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.coin__logo img {
  margin-right: 1.2rem;
  width: 4rem;
}

.coin__title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.coin__title h1 {
  font-size: 3.6rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  word-break: break-all;
}

.coin__symbol {
  color: var(--surface16);
  display: block;
  font-size: 2.4rem;
  font-weight: 300;
  margin: 0 0 0 2rem;
}

.coin__price {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 3.6rem;
  font-weight: 500;
}

.coin__change {
  font-size: 1.8rem;
  font-weight: 400;
  margin-left: 1.6rem;
}

.coin__favorite {
  grid-area: favorite;
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
}

.add-favorite {
  align-items: center;
  background: none;
  border: 1px solid var(--on-surface);
  color: var(--on-surface);
  cursor: pointer;
  display: flex;
  font-size: 1.82rem;
  justify-content: center;
  outline: none;
  padding: 1.6rem;
  width: 100%;
}

.add-favorite:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}

.add-favorite:active {
  background-color: darken(var(--primary), 4%);
  border-color: var(--primary);
  box-shadow: inset 0px 0px .5rem var(--surface6);
}

.add-favorite svg {
  margin: 0 1.2rem .3rem 0;
}

.coin__chart {
  grid-area: chart;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
}

.coin__figure {
  margin-bottom: 40px;
}

.coin__details {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 2rem;
}

.details__datapoint {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.6rem;
}

.datapoint__header {
  color: var(--surface16);
  font-size: 1.8rem;
  font-weight: 400;
}

.datapoint__data {
  font-size: 1.8rem;
  font-weight: 300;
}

.coin__aside { 
  grid-area: aside;
}

.coin__aside h3 {
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 1;
  padding-bottom: 2.4rem;
}

@media (min-width: 45rem) {
  .grid-container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 4rem 4rem;
    gap: 4rem 4rem;
    grid-template-areas: "header favorite" "chart aside";
  }

  .coin__header {
    align-items: center;
    flex-direction: row;
    margin-bottom: 4rem;
  }

  .coin__logo img {
    margin-right: 3rem;
    width: 6.4rem;
  }

  .coin__price {
    margin-left: 3rem;
  }

  .coin__chart {
    margin-bottom: 4rem;
  }

  .coin__details {
    align-items: center;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 0;
  }

  .details__datapoint {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 8.8rem;
  }

  .datapoint__header {
    font-size: 1.4rem;
  }

  .datapoint__data {
    margin-top: .6rem;
  }
}

.chart {
  background-color: var(--surface);
  min-height: 30rem;
}

.chart__select {
  background-color: var(--surface3);
  border: none;
  color: var(--on-surface);
  font-size: 1.6rem;
  font-weight: 300;
  margin: 1.6rem 1.6rem 0 0;
  padding: .6rem 1.6rem;
  float: right;
}

.chart--centered {
  align-items: center;
  display: flex;
  justify-content: center;
}

@media (min-width: 45rem) {
  .chart {
    background-color: var(--surface1);
    min-height: 50rem;
  }
}

.loading__notificaton {
  align-items: center;
  color: var(--surface16);
  display: flex;
  font-size: 2rem;
  padding: 1.8rem;
}
  
.loading__notificaton svg {
  margin-left: 2rem;
}

.text-input {
  border: 1px solid #cacccd;
  flex-grow: 1;
  font-size: 2rem;
  font-weight: 300;
  height: 5rem;
  padding: 1.2rem;
  width: 100%;
}

@media (min-width: 45rem) {
  .text-input {
    max-width: 37rem;
  }
}

.news-list {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.news-list__story {
  margin-bottom: 2.4rem;
}

.news-list__title {
  cursor: pointer;
}

.news-list__title a {
  color: var(--on-surface);
  text-decoration: none;
}

.news-list__subtitle {
  color: var(--surface16);
}

.footer {
  align-items: center;
  color: var(--surface12);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 4rem;
  padding: 4rem 0;
}

.footer div {
  margin-left: 4rem;
}

.footer a {
  color: var(--surface12);
  transition: color .2s;
}

.footer a:hover {
  color: lighten(var(--primary), 14%);
}



