.chart {
  background-color: var(--surface);
  min-height: 30rem;
}

.chart__select {
  background-color: var(--surface3);
  border: none;
  color: var(--on-surface);
  font-size: 1.6rem;
  font-weight: 300;
  margin: 1.6rem 1.6rem 0 0;
  padding: .6rem 1.6rem;
  float: right;
}

.chart--centered {
  align-items: center;
  display: flex;
  justify-content: center;
}

@media (min-width: 45rem) {
  .chart {
    background-color: var(--surface1);
    min-height: 50rem;
  }
}
