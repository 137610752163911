.footer {
  align-items: center;
  color: var(--surface12);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 4rem;
  padding: 4rem 0;
}

.footer div {
  margin-left: 4rem;
}

.footer a {
  color: var(--surface12);
  transition: color .2s;
}

.footer a:hover {
  color: lighten(var(--primary), 14%);
}
