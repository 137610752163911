.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.green-text {
  color: var(--positive);
}

.red-text {
  color: var(--error);
}

.light-text {
  font-weight: 300;
}
