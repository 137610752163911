.text-input {
  border: 1px solid #cacccd;
  flex-grow: 1;
  font-size: 2rem;
  font-weight: 300;
  height: 5rem;
  padding: 1.2rem;
  width: 100%;
}

@media (min-width: 45rem) {
  .text-input {
    max-width: 37rem;
  }
}
