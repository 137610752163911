.loading__notificaton {
  align-items: center;
  color: var(--surface16);
  display: flex;
  font-size: 2rem;
  padding: 1.8rem;
}
  
.loading__notificaton svg {
  margin-left: 2rem;
}
