.header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 1.6rem 0;
  width: 100%;
}

.header a {
  color: var(--on-surface);
  text-decoration: none;
}

.mobile-menu svg {
  margin-top: 1rem;
}

.nav {
  background: var(--primary);
  border-top: .2rem solid var(--primary);
  left: 0;
  margin-top: 2rem;
  max-height: 1000px;
  overflow: hidden;
  position: absolute;
  top: 7.4rem;
  transition:  max-height 1s;
  width: 100%;
  z-index: 1000;
}

.nav-hidden {
  max-height: 0px;
  transition: max-height .3s;
}

.header__title {
  font-family: 'Ubuntu';
  font-size: 3.6rem;
  margin: 0;
  padding: 0;
}

.nav__link {
  border-bottom: .2rem solid var(--primary);
  color: var(--on-surface);
  display: block;
  font-size: 2rem;
  padding: 1rem 2rem;
  text-decoration: none;
  transition: color .2s;
  width: 100%;
}

.nav__link:hover {
  color: var(--primary-light);
}

.nav__link--active {
  border-bottom: .2rem solid var(--primary);
}

@media (min-width: 45rem) {
  .nav {
    background: none;
    border: none;
    height: 3.4rem;
    left: auto;
    margin-top: 0;
    position: static;
    top: auto;
    width: auto;
    z-index: 1;
  }

  .nav-hidden {
    max-height: 1000px;
  }

  .nav__link {
    border: none;
    display: inline;
    font-size: 1.8rem;
    margin-left: 3rem;
    padding: 0 0 .6rem 0;
    width: auto;
  }
}

@media (min-width: 90rem) {
  .nav__link {
    margin-left: 4rem;
  }
}
