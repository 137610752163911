@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  background-color: var(--surface);
  color: var(--on-surface);
  font-family: 'Roboto', sans-serif;
  font-size: 1.6rem;
  line-height: 1.6;
}

h1 {
  font-size: 2.8rem;
  font-weight: 300;
  margin: 0 0 1.6rem 0;
  padding: 0;
}

h2 {
  font-size: 2.4rem;
  font-weight: 300;
  margin: 0 0 1.6rem 0;
  padding: 0;
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}