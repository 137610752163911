.content-container {
  margin: 0 auto;
  padding: 0 1.6rem;
  max-width: 120rem;
}

@media (min-width: 45rem) {
  .content-container {
    padding: 0 4rem;
  }
}
